<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;" class="mb-2">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">{{ popupData.title }}</v-col>
                <v-col class="text-right mr-0 mt-0 pt-0">
                    <v-btn text @click="closePopup" color="white"><v-icon large>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-row no-gutters>
                <v-col>
                    <v-card-text style="padding:30px 30px 20px 30px; font: normal normal normal 20px/22px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;" v-html="popupData.message"></v-card-text>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="popupData && popupData.sub_message">
                <v-col>
                    <v-card-text style="padding:0px 30px 20px 30px; font: normal normal normal 18px/20px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;" v-html="popupData.sub_message"></v-card-text>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-6 pb-5 pt-1 text-right">
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="closePopup()" :ref="'ok_btn'"><span style="font: normal normal bold 18px/21px Apple SD Gothic Neo;">확인</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data: () => ({}),
    computed: {
        ...mapGetters({
            popupModal: 'popupModal',
            popupType: 'popupType',
            popupData: 'popupData',
        }),
    },
    mounted() {
        let va = this
        setTimeout(() => {
            if (va && va.$refs && va.$refs.ok_btn) {
                va.$refs.ok_btn.$el.focus()
            }
        }, 300)

    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
    }
}
</script>
